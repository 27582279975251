.nav-link {
    &.active, &:hover {
        .nav-link-icon {
            .menu-icon {
                background-color: $black-color;
            }
        }
    }

    .nav-link-icon {
        .menu-icon {
            width: 2rem;
            height: 2rem;
            mask-size: contain;
            mask-repeat: no-repeat;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: $white-color;

            &.dashboard {
                mask-image: url(../images/dashboard.svg);
            }
            &.user {
                mask-image: url(../images/applicants-icon.svg);
            }
            &.file-text {
                mask-image: url(../images/schedule-icon.svg);
            }
            &.contents {
                mask-image: url(../images/contents.svg);
            }
            &.video {
                mask-image: url(../images/video.svg);
            }
            &.schedules {
                mask-image: url("../images/meetings-icon.svg");
            }
            &.briefcase {
                mask-image: url(../images/briefcase.svg);
            }
            &:hover {
                background-color: $black-color;
            }
        }
    }
}
