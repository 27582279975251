.section {
    &.content-files {
        display: flex;
        align-items: stretch;
        justify-content: stretch;
        padding: 2rem 3rem 0 3rem;
        background: url("../images/patches.png") no-repeat right 25rem;
        flex: 1;

        .header-section {
            font-size: $xl-font-size;
            letter-spacing: 0.03rem;
            text-shadow: -0.39rem 0.52rem 1.35rem rgba(0, 0, 0, 0.15);
            margin-bottom: 2.7rem;
        }

        .header-description {
            font-size: $base-ms-font-size;
            line-height: 2.8rem;
            letter-spacing: 0.075rem;
        }
    }
}

.main-information {
    &.task-list-item {
        display: flex;
        list-style: none;
        padding: 0.5rem 0;
        background-color: transparent;

        .tooltip-wrapper {
            display: flex;
            align-items: flex-end;

            .item-link {
                font-size: $base-font-size;
                font-weight: 500;
                cursor: pointer;
            }
        }
    }

    .document-type-icon {
        width: 2.2rem;
        height: 2.4rem;
        margin-right: 1rem;
        display: flex;
        background-size: cover;
        background-repeat: no-repeat;

        &.doc {
            background-image: url("../images/doc.svg");
        }

        &.jpg,
        &.jpeg {
            background-image: url("../images/jpg.svg");
        }

        &.xls {
            background-image: url("../images/xls.svg");
        }

        &.pdf {
            background-image: url("../images/pdf.svg");
        }

        &.png {
            background-image: url("../images/png.png");
        }

        &.doc {
            background-image: url("../images/doc.png");
        }

        &.zip {
            background-image: url("../images/zip.png");
        }

        background-image: url("../images/unknown_file.png");
    }

    .item-link {
        text-decoration: none;
        color: $black-color;
        font-weight: 600;
        font-size: $base-m-font-size;

        &:hover {
            color: $info-color;
            cursor: default;
        }
    }
}

.file-uploader {
    display: flex;
    flex: 1;
    border: 0.2rem dashed $border-color;
    background-color: $file-uploader-background;
    border-radius: 0.6rem;
    flex-direction: column;
    justify-content: stretch;
    align-items: stretch;
    color: rgba($black-color, 0.5);
    font-weight: 600;
    text-align: center;

    .rs-uploader-trigger-customize {
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        padding: 2.6rem 5rem;

        input {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            opacity: 0;
            cursor: pointer;
            width: 100%;
        }

        .upload-icon {
            background-image: url("../images/upload_icon.svg");
            height: 3.6rem;
            width: 3.8rem;
            object-fit: contain;
            opacity: 0.5;
            background-size: contain;
            background-repeat: no-repeat;
            margin-bottom: 1rem;
            align-self: center;
        }

        .upload-info-wrapper {
            display: flex;
            flex-direction: column;

            &:not(.progress-active) {
                padding: 0 5rem;
            }

            .upload-message {
                text-align: center;
                margin-bottom: 1.2rem;
            }

            .upload-button {
                font-size: $base-font-size;
                font-family: Montserrat, sans-serif;
                font-weight: 500;
                border-radius: $border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $info-color;
                color: $white-color;
                box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
                text-transform: none;
                padding: 1.5rem 2.5rem 1.5rem 2.5rem;
                letter-spacing: 0.039rem;

                &:hover,
                &:focus,
                &:active,
                &:hover:focus,
                &:hover:active,
                &:focus:active,
                &:hover:focus:active,
                &:not(:disabled):not(.disabled):active {
                    background: darken($info-color, 10%);
                    box-shadow: none;
                }

                i {
                    opacity: 0.6;
                    font-weight: 500;
                    margin-left: 1rem;
                }
            }

            .upload-progress-value {
                text-shadow: -0.8rem 1rem 2.7rem rgba(0, 0, 0, 0.15);
                letter-spacing: 0.062rem;
                font-weight: 500;
                font-size: $xm-font-size;
                margin-bottom: 1.2rem;
            }

            .upload-gauge {
                background-image: url('../images/stripes.svg');

                &[value] {
                    appearance: none;
                    border: none;
                    width: 100%;
                    height: 2rem;
                    border-radius: 1rem;
                    background-color: $progress-bar-background-color;
                    position: relative;
                    margin: 0 0 1.5rem;
                    box-shadow: -0.6rem 0.8rem 2.7rem 0 rgba(0, 0, 0, 0.1);

                    &::-webkit-progress-bar {
                        background-color: $progress-bar-background-color;
                        border-radius: 1rem;
                        overflow: hidden;
                    }

                    &::-webkit-progress-value {
                        background-color: $info-color;
                        background-image: url('../images/stripes.svg');
                        position: relative;
                        animation: animate-stripes 5s linear infinite;
                    }

                    &::-moz-progress-bar {
                        background-image: url('../images/stripes.svg');
                    }
                }

                .progress-bar {
                    background-color: $progress-bar-background-color;
                    border-radius: 1rem;
                    width: 100%;
                    height: 2rem;

                    .span {
                        background-image: url('../images/stripes.svg');
                        display: block;
                        text-indent: -999.9rem;
                    }
                }
            }
        }
    }

    &.drag-drop-hidden {
        border: none;
        flex: none;

        .rs-uploader-trigger {
            padding: 0;

            .rs-uploader-trigger-btn {
                font-size: $base-font-size;
                font-family: Montserrat, sans-serif;
                font-weight: 500;
                border-radius: $border-radius;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: $info-color;
                color: $white-color;
                box-shadow: 0.34rem 0.37rem 1.35rem 0 rgba(0, 0, 0, 0.1);
                text-transform: none;
                padding: 1.5rem 3.5rem 1.5rem 2.5rem;
                letter-spacing: 0.039rem;

                i {
                    opacity: 0.6;
                    font-weight: 500;
                    margin-left: 1rem;
                }

                &::after {
                    display: flex;
                    flex: 1;
                    justify-content: center;
                    align-items: center;
                    opacity: 0.6;
                    height: inherit;
                    content: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAYAAAALBAMAAACufOGlAAAAAXNSR0IB2cksfwAAAAlwSFlzAAALEwAACxMBAJqcGAAAACFQTFRF////AAAA////////////////////////////////////TiSQqAAAAAt0Uk5TIADw7zCg//ZX3PdkMzKtAAAAMElEQVR4nGNgEBRkUHYUZAgzEWQQTXZkEAyrYBCUTALSKxhEkxwZwlQEGZSA8kB1AIh8Bj/Xm+WHAAAAAElFTkSuQmCC');
                    position: absolute;
                    right: 2rem;
                }
            }

            &:hover,
            &:focus,
            &:active,
            &:hover:focus,
            &:hover:active,
            &:focus:active,
            &:hover:focus:active,
            &:not(:disabled):not(.disabled):active {

                .rs-uploader-trigger-btn {
                    background: darken($info-color, 10%);
                    box-shadow: none;
                }
            }
        }
    }
}

@keyframes animate-stripes {
    100% {
        background-position: -10rem 0;
    }
}