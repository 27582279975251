@import "../../../assets/styles/theme";


.noResults {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: $black-color;

  .title {
    margin-bottom: 2rem;
    text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
    font-size: $x-font-size;
    letter-spacing: 0.31px;
  }

  .description {
    font-size: $base-font-size;
    letter-spacing: 0.75px;
  }
}

.itemLink {
  text-decoration: none;
  color: $black-color;

  &:hover {
    cursor: pointer;
  }
}

.actionBtn {
  padding: 1rem;
  background-color: $white-color;
  color: $datepicker-header-color;
  font-size: $ml-font-size;

  &.disabled {
    color: rgba($base-text-color, 0.4);
    background-color: transparent;
    border-color: transparent;
  }
  &:hover {
    cursor: pointer;
  }
}


.btnContainer {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;

  .moreResults {
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    padding: 1rem;
    letter-spacing: 0.14px;
    color: $info-color;
    background-color: $white-color;

    i {
      margin-left: 1rem;
    }
  }
}
