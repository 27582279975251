@import "../../../assets/styles/theme";


.noResults {
  font-family: Montserrat, sans-serif;
  font-weight: 500;
  color: $black-color;

  .title {
    margin-bottom: 2rem;
    text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
    font-size: $x-font-size;
    letter-spacing: 0.31px;
  }

  .description {
    font-size: $base-font-size;
    letter-spacing: 0.75px;
  }
}

.itemLink {
  text-decoration: none;
  color: $black-color;

  &:hover {
    cursor: pointer;
  }
}

.status {
  padding: 0.75rem 1rem;
  border-radius: 1.6rem;
  border: solid 0.2rem $input-border-color;
  background-color: $white-color;
  opacity: 0.6;
  font-family: Montserrat, sans-serif;
  font-size: $small-font-size;
  font-weight: 500;
  text-align: center;
  color: rgba(0, 0, 0, 0.6);
}

.consultationStatus {
  opacity: 0.6;
  font-family: Montserrat, sans-serif;
  font-size: $small-font-size;
  font-weight: 500;
  letter-spacing: 0.36px;
  color: rgba(0, 0, 0, 0.6);
}

.meetingDate {
  span {
    margin-right: 1rem;
  }

  .cancelMeetingBtn {
    background-color: $white-color;

    &:hover {
      color: $info-color;
    }

    &[data-descr] {
      position: relative;

      &:hover,
      &:focus {
        &::after {
          content: attr(data-descr);
          position: absolute;
          top: -5.5rem;
          right: -9rem;
          padding: 1.3rem 3.2rem;
          border: 0.2rem $info-color solid;
          background-color: $white-color;
          border-radius: $border-radius;
          color: #000000;
          font-weight: 600;
          font-size: $small-base-font-size;
          white-space: nowrap;
          z-index: 1;
        }

        &::before {
          content: "";
          width: 0.75rem;
          height: 0.75rem;
          background-color: $white-color;
          transform: rotate(45deg);
          position: absolute;
          top: -1.4rem;
          border-right: 0.2rem solid $info-color;
          border-bottom: 0.2rem solid $info-color;
          z-index: 2;
        }
      }
    }
  }
}

.actionBtn {
    padding: 0.7rem 1rem;
    border-radius: 0.2rem;
    box-shadow: -0.3rem 0.4rem 1.35rem 0 rgba(0, 0, 0, 0.1);
    background-color: $info-color;
    color: $white-color;

  i {
    margin-left: 1rem;
  }

  &:hover {
    cursor: pointer;
  }
}
