@import "../../../../assets/styles/theme";


.modalHeader {
  width: 100%;
  padding: 2rem 2rem 0;

  .btnContainer {
    display: flex;
    justify-content: flex-end;

    button {
      opacity: 0.5;
      color: #050708;
      background-color: transparent;
    }
  }

  .modalTitle {
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 600;
    color: $black-color;
    margin-bottom: 1.7rem;
    text-align: center;
  }
}

.actionButtons {
  display: flex;
  justify-content: center;

  button:first-of-type {
    margin-right: 1rem;
  }
}
