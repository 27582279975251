@import "../../../../assets/styles/theme";

.profileInfoWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;


  .profileInfoImgContainer {
    display: flex;
    justify-content: center;
    height: 14rem;
    width: 14rem;
    border-radius: 50%;
    border: thin solid $border-color;
    overflow: hidden;
    margin-right: 1rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .profileInfoDetails {
    color: $black-color;
    font-size: $base-ms-font-size;
    font-weight: 500;
    letter-spacing: 0.045rem;
    text-align: center;

    .profileTitle {
      font-size: $xl-font-size;
      letter-spacing: 0.032rem;
      color: $username-color;
      margin-bottom: 1.9rem;
    }

    .profileMail {
      color: $info-color;
      margin-bottom: 1.1rem;
    }

    .profileInfoText {
      margin: 1.1rem 0;
    }
  }
}
