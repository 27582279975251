@import "../../../../assets/styles/theme";


.modalWrapper {
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .btnClose {
      background-color: $white-color;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .btnContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;
  }
}
