.mentor-profile-section {
  background: url("../../assets/images/patches.png") no-repeat right 45rem;

  .slots {
    display: flex;
    flex-wrap: wrap;

    .checkbox-container {
      margin: 0.25rem;

      &:hover {
        cursor: pointer;
      }

      .checkbox-wrapper {
        float: left;
        display: flex;
        justify-content: center;
        align-items: center;
        border: none;
        padding: 0;
        height: unset;

        .checkbox-input {
          height: 100%;
          width: 100%;
        }

        .checkbox-label {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: $small-font-size;
          font-weight: 600;
          background-color: $secondary-color;
          color: $white-color;
          border-radius: 0.4rem;
          padding: 1rem 1.6rem;
          min-width: 6.6rem;
        }

        .checkbox-input:disabled + .checkbox-label {
          opacity: 0.7;
        }

        .checkbox-checkmark {
          display: none;
        }

        &.unavailable {
          .checkbox-label {
            background-color: rgba($secondary-color, 0.1);
            color: $black-color;
          }
        }
      }
    }
  }

  .nav {
    &.nav-tabs {
      background-color: unset;
      border: none;

      .nav-link {
        font-weight: 600;
        color: $grey-text-color;
        text-transform: unset;
        opacity: 0.6;
        border: none;

        &:hover {
          opacity: 1;
        }

        &.active {
          border-bottom: 0.2rem solid $info-color;
          color: $black-color;
          opacity: 1;
        }
      }
    }
  }

  .switch {
    &.sm {
      width: 2.5rem;
      height: 1.5rem;

      input:checked + .slider:before {
        transform: translateX(1.1rem);
      }

      .slider {
        &::before {
          height: 1.2rem;
          width: 1.2rem;
          top: 0;
          left: 0;
        }
      }
    }
  }

  .month-timeslots-form {
    .month-calendar {
      .react-datepicker__current-month {
        font-weight: 600;

      }

      .react-datepicker__day {
        color: rgba($black-color, 0.5);
        font-weight: 600;

        &--disabled {
          color: $snippet-bg-color;
        }

        &--keyboard-selected {
          background-color: rgba($secondary-color, 0.1);
          font-weight: 600;
          color: rgba($black-color, 0.5);
        }

        &.react-datepicker__day--selected {
          color: $white-color;
          background-color: $secondary-color;
        }
      }

      .react-datepicker__day-names {
        background-color: $school-form-background;

        .react-datepicker__day-name {
          color: $black-color;
        }
      }

      .react-datepicker {
        .react-datepicker__navigation {
          width: 3rem;
          height: 3rem;
          max-height: 3rem;
          text-align: center;
          cursor: pointer;
          position: absolute;
          top: -0.7rem;
          padding: 0;
          z-index: 1;
          text-indent: -999em;
          overflow: hidden;

          &--previous {
            left: 5%;
            background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-left' class='svg-inline--fa fa-chevron-left fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23888' d='M34.52 239.03L228.87 44.69c9.37-9.37 24.57-9.37 33.94 0l22.67 22.67c9.36 9.36 9.37 24.52.04 33.9L131.49 256l154.02 154.75c9.34 9.38 9.32 24.54-.04 33.9l-22.67 22.67c-9.37 9.37-24.57 9.37-33.94 0L34.52 272.97c-9.37-9.37-9.37-24.57 0-33.94z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
            background-size: 25%;

            &--disabled,
            &--disabled:hover {
              border-right-color: $datepicker__navigation-disabled-color;
              cursor: default;
            }
          }

          &--next {
            right: 5%;

            background: url("data:image/svg+xml,%3Csvg aria-hidden='true' focusable='false' data-prefix='fas' data-icon='chevron-right' class='svg-inline--fa fa-chevron-right fa-w-10' role='img' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 320 512'%3E%3Cpath fill='%23888' d='M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z'%3E%3C/path%3E%3C/svg%3E") no-repeat center;
            background-size: 25%;

            &--disabled,
            &--disabled:hover {
              border-left-color: $datepicker__navigation-disabled-color;
              cursor: default;
            }
          }
        }
      }
    }
  }
}

