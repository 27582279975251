@import "../../../../assets/styles/theme";


.consultationPackageSummary {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1rem 1.5rem 1rem 1rem;
  border-radius: 0.6rem;
  box-shadow: 0 0 1.5rem 0 rgba(0, 0, 0, 0.1);
  border: solid transparent 0.3rem;
  margin: 0.9rem 0;

    &:hover {
      border: solid $info-color 0.3rem;
      border-radius: 0.6rem;
      cursor: default;

      .tooltipIcon {
        cursor: pointer;
      }
    }

  .title {
    margin-left: 1rem;
    display: flex;
    flex: 1;
  }

  .infoPart {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-right: 1.5rem;

    .serviceStatus {
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba($snippet-bg-color, 0.5);
      color: rgba($black-color, 0.6);
      border-radius: 0.2rem;
      font-weight: 600;
      padding: 0.9rem 1.9rem 0.8rem 2rem;
      margin: 0 1rem;
      letter-spacing: 0.019rem;
      min-width: 10rem;

      &.processing {
        background-color: rgba($switch-pending-color, 0.2);
        color: $switch-pending-color;
      }
    }
  }

  .tooltipInfo {
    min-width: 19rem;
    padding: 0 1rem;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.15;
    letter-spacing: 0.033rem;
    text-align: center;
    color: $black-color;
  }

  .tooltipIcon {
    width: 1.6rem;
    height: 1.6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba($black-color, 0.2);
    border-radius: 50%;
    font-family: Montserrat, sans-serif;
    font-size: $xxs-font-size;
    font-weight: bold;
    line-height: 1.53;
    color: $white-color;

    &:hover {
      background-color: $info-color;
      outline: none;
    }
  }

  &:focus {
    outline: none;
  }
}
