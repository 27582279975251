.onboarding-page {
  .onboarding-section {
    background: url("../../assets/images/onboarding_background.png") no-repeat top center;
    background-size: cover;
  }
}

@media only screen and (max-width: $breakpoint-xlarge) {
  .onboarding-page {
    .onboarding-section {
      background-size: cover;
    }
  }
}
