@import "../../../assets/styles/theme";

.multiselectContainer {
  display: flex;
  width: 100%;
  margin-bottom: 2.6rem;

  .editBtnContainer {
    display: flex;

    button {
      background: transparent;
      padding: 0 0.5rem;

      &:hover {
        color: $info-color;
      }
    }
  }
}
