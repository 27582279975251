@import "../../../../../../../assets/styles/theme";

.calendarTimeSlot {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  font-size: $small-font-size;
  height: 2rem;
  margin: 0.5rem;
  font-weight: 500;
  padding: 0.5rem;
  border-radius: $border-radius;
  background-color: $time-slot-background;
  color: $black-color;
  text-decoration: line-through;
  cursor: pointer;

   &.isFree {
     background-color: $secondary-color;
     color: $white-color;
     text-decoration: none;
   }
}
