@import "../../../../assets/styles/theme";


.title {
  text-shadow: 0.44rem 0.48rem 1.35rem rgba(0, 0, 0, 0.15);
  font-family: Montserrat, sans-serif;
  font-size: $xl-font-size;
  font-weight: 500;
  letter-spacing: 0.31px;
  color: $black-color;
}

.notSelected {
  font-family: Montserrat, sans-serif;
  font-size: $base-font-size;
  font-weight: 500;
  letter-spacing: 0.45px;
  color: rgba(0, 0, 0, 0.3);
}
