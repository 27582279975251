@import "../../../../assets/styles/theme";


.studyFieldsWrapper {
  display: flex;
  flex-wrap: wrap;

  & > div {
    margin: 0 1rem 1rem 0;

    &:last-of-type {
      margin-right: 0;
    }
  }
}
