@import "~bootstrap/scss/bootstrap";
@import "font/feather";

@import "~educat-styles/normalize";
@import "~educat-styles/panel/theme-variables";
@import "~educat-styles/panel/style";
@import "~educat-styles/panel/form-controls/style";
@import "auth";
@import "onboarding";
@import "menu-icons";
@import "timeslots";
@import "content-files";
@import "form";

.basic-multi-select {
  width: 100%;
}

form {
	label[for='addToEducat'] + .checkbox-container {
		margin-top: 2rem;
	}

	.consultation-date {
		flex-direction: row;
		align-items: center;

		label {
			margin: 0 1rem 0 0;
		}

		.react-datepicker-host {
			.react-datepicker-wrapper:after {
				font-size: 1.4rem;
			}
		}
	}
}

.add-schedule-form {
	.row {
		.add-schedule-control {
			flex-direction: row;
			align-items: center;

			label {
				display: flex;
				flex: 1;

				&[for=school] + .basic-single {
						.select__control {
							.select__indicators {
								display: none;
							}
						}
					}

			}

			& > div {
				display: flex;
				flex: 2;
			}
		}
	}
}

.schedule-list-filters {
	.add-schedule-form {
		display: flex;

		.row {
			flex: 1;
			margin-right: 1rem;

			.add-schedule-control {
				flex-direction: column;
				justify-content: flex-start;
				align-items: flex-start;

			}
		}
	}
}

.sr-only {
  border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	height: 1px !important;
	margin: -1px !important;
	overflow: hidden !important;
	padding: 0 !important;
	position: absolute !important;
	width: 1px !important;
	white-space: nowrap !important;
}

// react stars component custom stars
.star-icon {
	width: 1.8rem;
	height: 1.8rem;
	background-size: cover;
	display: flex;
	&.full {
		background-image: url(../../assets/images/star_full.svg);
	}
	&.empty {
		background-image: url(../../assets/images/star_empty.svg);
	}
}
// common rank wrapper styles
.rank-wrapper {
    display: flex;
    flex-direction: column;
    color: $black-color;

    .rank-number {
        font-weight: 600;
        font-size: $ml-font-size;
        .rank-place {
            margin-right: 0.5rem;
        }
    }

    .rank-text {
        color: $black-color;
        text-align: right;
        white-space: nowrap;
        font-size: $small-font-size;
        font-weight: 500;
    }
}

// common button wrapper - buttons pushed to end by default
.button-wrapper {
	display: flex;
  	justify-content: flex-end;
  	align-items: center;
	  &.centered {
		justify-content: center;
	  }
	  &.start-align {
		  justify-content: flex-start;
	  }
}

// common main title styling

.secondary-title {
	text-shadow: -0.8rem 1rem 2.7rem rgba(0, 0, 0, 0.15);
	margin-bottom: 1.4rem;
	font-family: "Montserrat", sans-serif;
	font-size: $ml-font-size;
	font-weight: 500;
	letter-spacing: 0.25px;
	color: $black-color;
	&.big-margin {
		margin-bottom: 2.4rem;
	}
}
.loading-dummy-page {
	height: 75vh;
	width: 100vw;
	display: flex;
}

.applicants-section {
	background: url("../../assets/images/patches.png") no-repeat right center;

	.user-img {
		margin-bottom: 2rem;
	}
}

// negative margin to events calendar:

.dashboard-event-calendar {
	margin-top: -3rem;
}

.insurances-table {
	.tooltip-wrapper {
		.tooltip-content {
			width: auto;
		}
	}
}

.modal-content-container {
	&.edit-insurance-modal {
		max-height: 90%;
		overflow-y: scroll;

		.custom-card {
			.custom-card-header {
				.custom-card-title {
					display: flex;
					align-items: center;
					justify-content: space-between;
					margin-bottom: 1rem;

					button {
						background-color: transparent;
					}
				}
			}
		}
	}
}

.insurance-data-form {
	.react-datepicker-host {
		.react-datepicker-wrapper {
			.react-datepicker__input-container {
				&:after {
					padding-left: 3rem;
					top: calc(50% - 1rem);
					right: 0.5rem;
					height: 2rem;
					border-left: 0.2rem solid $input-border-color;
					background: url('../../assets/images/calendar.svg') center no-repeat;
				}
			}

			&.focused {
				.react-datepicker__input-container {
					&:after {
						background: url('../../assets/images/calendar-active.svg') center no-repeat;
					}
				}
			}
		}
	}
}

@media (max-width: $breakpoint-xlarge) {
	.dashboard-event-calendar {
		margin-top: 0;
	}
}
