@import "../../../../assets/styles/theme";


.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .date {
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    letter-spacing: 0.42px;
    color: $black-color;

    span {
      margin-left: 0.5rem;
    }
  }
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;
}
