@import "../../../../assets/styles/theme";


.modalHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 1rem;

  .modalTitle {
    margin-right: 2rem;

    h2 {
      text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
      font-family: Montserrat, sans-serif;
      font-size: $x-font-size;
      font-weight: 500;
      letter-spacing: 0.31px;
      color: $black-color;
      margin-bottom: 1rem;

      .scheduleSchool {
        margin-left: 0.5rem;
      }
    }

    p {
      text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
      font-family: Montserrat, sans-serif;
      font-size: $base-m-font-size;
      font-weight: 500;
      letter-spacing: 0.2px;
      color: rgba(0, 0, 0, 0.6);
    }
  }

  .btnClose {
    color: rgba(0, 0, 0, 0.4);
    background-color: $white-color;
  }
}

.actionBtn {
  padding: 1rem;
  background-color: $white-color;
  color: $datepicker-header-color;
  font-size: $ml-font-size;

  &.disabled {
    &:hover {
      cursor: not-allowed;
    }
  }

  &:hover {
    cursor: pointer;
  }
}

.btnContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;
  margin-top: 2rem;

  button:first-of-type {
    margin-right: 1.8rem;
  }
}
