@import "../../../../assets/styles/theme";


.itemLink {
  text-decoration: none;
  color: $black-color;
  font-weight: 600;

  &:hover {
    cursor: pointer;
  }
}
