@import "../../../assets/styles/theme";

.container {
  padding: 2rem 4rem;
  border-radius: 0.4rem;
  box-shadow: 0 0 7.5px 0 rgba(0, 0, 0, 0.1);
  margin-bottom: 1rem;
  display: flex;
  justify-content: space-between;

  .info {
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      min-width: 2rem;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: $primary-color;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $white-color;
      margin-right: 1rem;
    }

    .text {
      text-shadow: -3.9px 5.2px 13.5px rgba(0, 0, 0, 0.15);
      font-family: Montserrat, sans-serif;
      font-size: $l-font-size;
      font-weight: 500;
      line-height: 1.4;
      letter-spacing: 0.31px;
      color: $black-color;
    }
  }

  .details {
    display: flex;
    align-items: center;

    .applicantDetails {
      margin-right: 2rem;
      display: flex;
      align-items: center;

      .applicantImgContainer {
        display: flex;
        justify-content: center;
        height: 3.5rem;
        width: 3.5rem;
        min-width: 3.5rem;
        border-radius: 50%;
        background-color: $image-background-color;
        overflow: hidden;
        margin-right: 1rem;

        img {
          height: 100%;
          width: 100%;
        }
      }

      .applicantName {
        font-family: Montserrat, sans-serif;
        font-size: $base-m-font-size;
        font-weight: bold;
        letter-spacing: 0.4px;
        color: $info-color;
      }
    }

    .serviceDetails {
      border-left: solid 1px #ddd;
      padding-left: 1rem;

      .label {
        font-family: Montserrat, sans-serif;
        font-size: $small-font-size;
        font-weight: 500;
        letter-spacing: 0.3px;
        color: $black-color;
        margin-bottom: 0.3rem;
      }

      .service {
        font-family: Montserrat, sans-serif;
        font-size: $small-font-size;
        font-weight: bold;
        letter-spacing: 0.3px;
        color: $black-color;

        &:first-of-type {
          margin-bottom: 1rem;
        }
      }
    }
  }
}
