@import "../../assets/styles/theme";


.schedulesSection {
  display: flex;
  flex: 1;
  background: url("../../assets/images/patches.png") no-repeat right 15rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .newScheduleDescription {
    margin-bottom: 2.6rem;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    letter-spacing: 0.42px;
    color: $black-color;
  }
}
