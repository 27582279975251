@import "../../assets/styles/theme";


.applicantsSection {
  display: flex;
  flex: 1;
  background: url("../../assets/images/patches.png") no-repeat right 15rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .actions {
      display: flex;

      .filtersContainer {
        border-radius: 0.2rem;
        border: solid 1px $input-border-color;
        display: flex;
        margin-right: 2rem;

        button {
          background-color: $white-color;
          padding: 0.5rem 0.7rem;
          font-family: Montserrat, sans-serif;
          font-size: $small-base-font-size;
          letter-spacing: 0.39px;
          text-align: left;
          color: $black-color;

          &:nth-child(2) {
            border-left: 1px solid $input-border-color;
            border-right: 1px solid $input-border-color;
          }

          &.active {
            font-weight: bold;
          }
        }
      }
    }
  }
}
