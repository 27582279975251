@import "../../../../assets/styles/theme";

.calendarGeneratorWrapper {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2rem;

  .calendarGeneratorHeader {
    margin-bottom: 1rem;
  }

  .calendarWeekTitle {
    font-weight: 500;
    margin-bottom: 1rem;
    padding-right: 3rem;
  }
}



