@import "../../../../assets/styles/theme";


.documentTypeIcon {
  width: 2.2rem;
  height: 2.4rem;
  margin: 0 1.5rem;
  display: flex;
  background-size: cover;
  background-repeat: no-repeat;

  &.doc {
    background-image: url('../../../../assets/images/doc.svg');
  }

  &.jpg {
    background-image: url('../../../../assets/images/jpg.svg');
  }

  &.xls {
    background-image: url('../../../../assets/images/xls.svg');
  }

  &.pdf {
    background-image: url('../../../../assets/images/pdf.svg');
  }
}

.itemLink {
  text-decoration: none;
  color: $black-color;
  font-weight: 600;

  &:hover {
    font-weight: 600;
  }
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button:first-of-type {
    margin-right: 1rem;
  }
}
