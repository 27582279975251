@import "../../assets/styles/theme";


.consultationsSection {
  display: flex;
  flex: 1;
  background: url("../../assets/images/patches.png") no-repeat right 15rem;

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
