@import "../../../assets/styles/theme";

.applicantDetails {
  max-width: 22rem;
  min-width: 22rem;
  display: flex;
  flex: 1 1 0;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  padding: 2rem 1rem;
  border-radius: 0.3rem;
  box-shadow: 0 0 0.75rem 0 rgba(0, 0, 0, 0.1);
  background-color: $white-color;
  margin: 0 1rem 1rem 0;

  .applicantImgContainer {
    display: flex;
    justify-content: center;
    min-width: 10rem;
    width: 10rem;
    height: 10rem;
    border-radius: 50%;
    background-color: $image-background-color;
    overflow: hidden;
    margin-bottom: 1.6rem;

    img {
      height: 100%;
      width: 100%;
    }
  }

  .details {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    font-family: Montserrat, sans-serif;
    color: $black-color;
    font-size: $small-font-size;
    font-weight: 600;
    letter-spacing: 0.03rem;

    .name {
      font-size: $base-m-font-size;
    }

    .school, .label {
      font-weight: 500;
      line-height: 1.5;
    }

    .label {
      margin: 0.65rem 0;
    }

    .studyField {
      line-height: 1.5;
      color: $info-color;
      text-transform: uppercase;
    }
  }
}
