.auth-page {
  .auth-content {
    background: url('../images/registration-background.png') no-repeat center center;
    background-size: contain;
    position: relative;
    overflow: hidden;

    &:before {
      content: '';
      background: url("../images/registration_image.png") no-repeat bottom center;
      background-size: contain;
      position: absolute;
      bottom: -15rem;
      height: 100%;
      width: 30%;
      left: 5rem;
    }
  }
}

@media only screen and (max-width: $breakpoint-full) {
  .auth-page {
    .auth-content {
      &:before {
        bottom: -9rem;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xxlarge) {
  .auth-page {
    .auth-content {
      &:before {
        bottom: -4rem;
        left: 0;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-xlarge) {
  .auth-page {
    .auth-content {
      background-size: cover;

      &:before {
        bottom: -5rem;
        left: 0;
      }
    }
  }
}

@media only screen and (max-width: $breakpoint-small) {
  .auth-page {
    .auth-content {
      background-size: cover;

      &:before {
        display: none;
      }
    }
  }
}

@media only screen and (max-height: $breakpoint-small) {
  .auth-page {
    .auth-content {
      background-size: cover;

      &:before {
        height: 65%;
      }
    }
  }
}
