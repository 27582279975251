@import "../../../../../assets/styles/theme";


.modalWrapper {
  .closeModalContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;

    .btnClose {
      background-color: $white-color;
      color: rgba($black-color, 0.4);
    }
  }

  .modalHeader {
    margin-bottom: 1.6rem;
    text-align: center;
    text-shadow: -0.78rem 1.04rem 2.7rem rgba($black-color, 0.15);
    font-family: Montserrat, sans-serif;
    font-size: $x-font-size;
    font-weight: 500;
    line-height: 1.4;
    letter-spacing: 0.063rem;
    color: $black-color;
  }

  .modalSubtitle {
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      line-height: 1.6;
      letter-spacing: 0.15rem;
      text-align: center;
      color: $black-color;
  }

  .formContainer {
    display: flex;
    justify-content: center;

    form {
      width: 80%;
      padding: 3rem 0;
    }
  }

  .btnContainer {
    display: flex;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
  }
}
