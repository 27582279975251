@import "../../../../assets/styles/theme";


.modalWrapper {
  .modalHeader {
    display: flex;
    flex-direction: column;
    margin-bottom: 2rem;

    .headerBtn {
      display: flex;
      justify-content: flex-end;
      width: 100%;

      .btnClose {
        background-color: $white-color;
        color: rgba(0, 0, 0, 0.4);
      }
    }

    .modalTitle {
      margin-bottom: 1.3rem;
    }

    .description {
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      letter-spacing: 0.75px;
      color: $black-color;
    }
  }

  .scheduleContainer {
    margin-bottom: 0.5rem;
  }

  .btnContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    button:first-of-type {
      margin-right: 1rem;
    }
  }
}
