@import "../../../../../../assets/styles/theme";

.abstractDay {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  list-style: none;
  flex: 1;

  .abstractDayHeader {
    padding: 1rem 0;
    .headerWrapper {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;

      .headerTitle {
        font-weight: 600;
        font-size: $base-font-size;
        padding: 0 1rem;
        flex: 1;
        text-align: center;
        margin-bottom: 1rem;
      }

      &.lastDay {
        border-right: none;
      }
    }
  }
}
