@import "../../../../assets/styles/theme";


.itemLink {
  text-decoration: none;
  color: $black-color;
  font-weight: 600;

  &:hover {
    font-weight: 600;
  }
}

.statusWrapper {
  position: relative;

  .status {
    padding: 0.65rem 1.15rem;
    border-radius: 1rem;
    border: solid 1px $input-border-color;
    background-color: $white-color;
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    letter-spacing: 0.42px;
    color: rgba(0, 0, 0, 0.6);

    &:hover {
      background-color: $info-color;
      border-color: $info-color;
      color: $white-color;
    }
  }

  .optionsWrapper {
    position: absolute;
    opacity: 0.95;
    background-color: #fff;
    z-index: 9;
    display: flex;
    flex-direction: column;
    padding: 0 5rem;

    .status {
      margin-bottom: 0.3rem;
    }
  }
}

.expandBtnWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  .expandBtn {
    font-family: Montserrat, sans-serif;
    font-size: $base-font-size;
    font-weight: 500;
    color: $info-color;
    background-color: $white-color;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    margin: 1.2rem 0;
  }
}

.btnWrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 2rem;

  button:first-of-type {
    margin-right: 1rem;
  }
}
