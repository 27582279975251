@import "../../../../assets/styles/theme";

.calendarContainer {
  display: flex;
  justify-content: stretch;
  align-items: stretch;
  flex-direction: column;

  .calendarWrapper {
    display: flex;
    flex-direction: column;
    min-width: 30rem;
    font-family: Gotham-Medium, sans-serif;

    h4 {
      font-size: $base-font-size;
      font-weight: bold;
      color: $grey-text-color;
    }

    .formWrapper {
      position: relative;
      form {
        label {
          &+div {
            width: 100%;
          }
        }
      }
      .footerContainer {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        padding: 2rem 0;
      }
    }
  }
}
