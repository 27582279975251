@import "../../../assets/styles/theme";

.videoWrapper {
  width: 100%;

  .videoContent {
    width: 100%;
  }

  p {
    a {
      color: $info-color;
      margin-left: 0.5rem;
      font-size: inherit;
    }
  }
}

.btnContainer {
  display: flex;
  justify-content: space-between;
}

.inputWrapper {
  width: 100%;
  display: flex;

  input {
    display: block;
    width: 100%;
    height: 5rem;
    padding: 0.35rem 1.5rem;
    font-size: 1.6rem;
    font-weight: 400;
    line-height: 1.5;
    color: $black-color;
    background-color: $white-color;
    border: 0.2rem solid $input-border-color;
    border-radius: 0.4rem;
  }

  .editBtnContainer {
    display: flex;

    button {
      background: transparent;
      padding: 0 0.5rem;

      &:hover {
        color: $info-color;
      }
    }
  }
}
