@import "../../../assets/styles/theme";


.modalWrapper {
  .modalHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 2rem;

    .btnClose {
      background-color: $white-color;
      color: rgba(0, 0, 0, 0.4);
    }
  }

  .scheduleEventsContainer {
    margin-top: 3rem;
    max-height: 30rem;
    min-height: 50vh;
    overflow-y: auto;
    overflow-x: hidden;

    .scheduleEventsHeader {
      background-color: $file-uploader-background;

      .tHeadCell {
        border-bottom: 0.1rem solid $table-bottom-border-color;
        border-right: 0.1rem solid $table-bottom-border-color;

        &:last-child {
          border-right: none;
        }

        .tHead {
          display: flex;
          align-items: center;
          padding: 0 3rem;
          height: 5rem;
          font-size: $base-m-font-size;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.36);
        }
      }
    }
  }


  .btnContainer {
    display: flex;
    flex: 1;
    justify-content: flex-end;
    align-items: center;
    margin-top: 1rem;

    button:first-of-type {
      margin-right: 1rem;
    }

    .addEvent {
      margin: 1rem 0;
      font-family: Montserrat, sans-serif;
      font-size: $base-font-size;
      font-weight: 500;
      letter-spacing: 0.14px;
      color: $info-color;
      text-decoration: underline;
      background-color: $white-color;
    }
  }
}
