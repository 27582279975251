@import "../../assets/styles/theme";

.dashboard {
  background: url("../../assets/images/patches.png") no-repeat right bottom;
  display: flex;
  flex: 1;
}

.applicantsList {
  display: flex;
  flex-wrap: wrap;
}
